.feedback-form{
    label{
        color: #545454;
    }
    .message{
        min-height: 120px;
        max-height: 120px;
    }

    .btn-group {

        &.feedback-ratings {
            gap: 10px;
            flex-flow: wrap;
        }

        .btn-primary {
            background-color: #212529;
            background-color: transparent;
            color: #212529;
            border-color: #212529 !important;
            flex: none;
            width: 50px;
            height: 50px;
            &:hover {
                color: #ffffff;
                background-color: #3961b2;
                border-color: #3961b2 !important;
            }
        }
        .btn-warning {
            flex: none;
            width: 50px;
            height: 50px;
            background-color: #3961b2;
            &:focus {
                box-shadow: none;
            }
        }

    }
}