footer{
    background-color: #212529;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    p{
        color: #ffffff;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;

        span{
            color: #3861b3;
        }
    }
}