.user-dropdown{

    &-container{
        position: relative;
    }
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 10px;

    @media (max-width: 767px) {
        padding-right: 0;
        padding-left: 25px;
    }
    span{
        font-size: 12px;
        line-height: 14px;
    }

    .no-gutters{
        line-height: 16px;
        margin-top: 0;
        align-items: center;
    }

    .thumbnail{
        width: 35px;
        min-height: 30px;
        border-radius: 50%;
        height: 100%;
        background-color: #ffffff;
        background-size: cover;
        background-position: center center;
        @media (max-width: 767px) {
            width: 30px;
        }
    }
    .name{
        color: #ffffff;
        font-size: 12px;
        margin-bottom: 4px;
        display: block;
    }
    .credits{
        color: #ffffff;
        text-transform: uppercase;
        border-right: 0px solid #ffffff;
    }
    .time{
        color: green;
    }

    .icon{
        height: 100%;
        width: 20px;
        text-align: center;
        cursor: pointer;
        color: #ffffff;
    }
}

.dropdown-menu{
    border-radius: 0;
    margin-top: 10px;
    margin-right: 10px;
    border: 0;
    box-shadow: 0 2px 3px #d5d5d5;
    min-width: 185px;
    &.active{
        display: block;
    }

    .dropdown-item{
        font-size: 14px;
    }
}