.nomore_profile {

     margin-top: 1.5em;   
    .message_title{
        font-size: clamp(30px, 5vw, 35px);
    }

    .message_note{
        font-size: clamp(16px, 5vw, 18px);
        margin-bottom: 0;
    }

    .btn-reset_profile {
        margin-top: 1.5em;
        min-width: 150px;
        padding: 6px 15px;
        font-size: 18px;
        text-transform: capitalize;
        background: #3861b3;
        color: #ffffff;
        border: 2px solid transparent;
        transition: all 0.2s ease-out;

        &:hover {
            color: #3861b3;
            background: #ffffff;
            border-color: #3861b3;
            transition: all 0.2s ease-out;
        }
    }
}