.preferences-container {
    padding-left: 15px;
    padding-right: 15px;

    .title {
        font-size: 30px;
        font-weight: 400;
    }

    .subtitle {
        font-size: 22px;
    }

    .preference-item {
        cursor: pointer;

        &:hover,
        &.active {
            background-color: #f4f4f4;
        }

        .title {
            font-size: 18px;
        }

        .description {
            font-size: 16px;
        }
    }
}