.f-14 {
    font-size: 14px !important;
}

.border-2 {
    border-width: 2px !important;
}

.border-rounded {
    border-radius: 10px !important;
    border-color: #000000 !important;
}

#addNewCard .bg-dark,
#upgradeSubscription .bg-dark {
    background-color: #212529 !important;
}

.transactions-table {
    border-color: #000000 !important;
    border-radius: 10px;
    text-align: center;
}