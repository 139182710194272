.dashboard-container{
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
    border: 2px solid #dddddd;
    height: auto;
    min-height: calc(100% - 50px);
    overflow: hidden;

    .welcome{
        &-name{
            font-weight: 400;
            @media (max-width: 767px) {
                font-size: 35px;
            }
        }

        &-subtitle{
            font-size: 15px;
        }
    }

    .list-group {
        @media (max-width: 575px) {
            font-size: 12px;
        }
    }

    .schedule_btn_circle{
        position: relative;
        transition: all 0.5s ease-out;
        &:not(.disabled):hover{
            transition: all 0.5s ease-out;
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                border: 1px solid #3861b3;
                position: absolute;
                top: 0;
                left: 0;
                // border-radius: 50%;
                background: #ffffff;
                transition: all 0.5s ease-out;
            }
    
            &::after{
                content: "-1 Credit";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: #3861b3;
                font-size: 12px;
                font-weight: 600;
                transition: all 0.5s ease-out;
            }
        }
    }

    .howitworks{
        &-title{
            font-size: 18px;
            font-weight: 700;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #000;
            cursor: pointer;
            width: 220px;
            .phone-icon{
                border: 1px solid green;
                width: 25px;
                height: 25px;
                margin-right: 10px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &:hover {
                background-color: #3861b3;
                color: #ffffff;
                border-color: #ffffff;

                .phone-icon {
                    border-color: #ffffff;
                    svg {
                        fill: #ffffff;
                    }
                }
            }
        }

        &-list{
            padding: 0;
            text-align: left;
            display: grid;
            justify-content: left;

            li{
                line-height: 2em;
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: left;
                padding-left: 0;
                border: 0px solid #dddddd;
                position: relative;

                @media (max-width: 575px) {
                    font-size: 14px;
                }

                &::before{
                    content: '';
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    border: 1px solid #000000;
                    position: relative;
                    margin-right: 10px;
                }
            }
        }
    }

    .questions{
        &-title{
            font-size: 18px;
            font-weight: 700;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #000000;
            cursor: pointer;
            width: 220px;
            .chat-icon{
                margin-right: 5px;
            }
            &:hover {
                background-color: #3861b3;
                color: #ffffff;
                border-color: #ffffff;

                .chat-icon {
                    border-color: #ffffff;
                    svg {
                        color: #ffffff !important;
                    }
                }
            }

        }

        &-subtitle{
            margin-top: 0.5em;
            margin-bottom: 1em;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 2px;
        }

        &-btn-getstarted{
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1px;
            position: relative;

            &:hover {
                &::before{
                    content: "-1 Credit";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: #007bff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    p {
        font-size: 14px;
    }
}

.modal-backdrop {
    background-color: #ffffff;
    &.show {
        opacity: 0.9;
    }
}

#app_download_sec {
    #info {
        @media (max-width: 767px) {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    #logos {

        img {
            width: 200px;
        }
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;

            img  {
                max-width: 200px;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}