#supportForm {
    label {
        span {
            color: red;
            &.readonly{
                color: #3961b2;
                font-size: 12px;
                font-weight: 600;
                padding-bottom: 1px;
                border-bottom: 1px solid;
                margin-left: 1em;
                cursor: pointer;
            }
        }
    }
}