.stepsform {
    &-container {
        position: fixed;
        top: 0;
        z-index: 9999;
        width: 100%;
        height: 100%;
        background-color: #e7e7e7;
        overflow-y: auto;
        padding-bottom: 2em;
        color: #000000;

        .formContainer {
            padding-top: 3em;
        }

        .btn_disabled {
            position: relative;
            &::after {
                content: "Please fill all the fields";
                position: absolute;
                left: 0;
                font-size: 11px;
                color: red;
                bottom: -22px;
            }
        }
    }

}

.step-tracker {
    list-style: none;
    padding: 0;
    margin-bottom: 2em;
    display: flex;
    justify-content: space-evenly;
    border-radius: 5px;
    overflow: hidden;

    li {
        border-bottom: 5px solid #ffffff;
        width: 100%;
        transition: all 0.3s ease-in-out;
        &.active {
            border-color: #3961b2;
            transition: all 0.3s ease-in-out;
        }

        span{
            display: none;
        }
    }
}

.rounded-container {
    border-radius: 25px;
    background: #ffffff;
    padding: 2em 1.5em;
    overflow: hidden;

    &.userdetails {

        padding: 0;

        .inner-container {
            padding: 3.5em 1.5em 2em;
        }

        .coverimage {
            min-height: 100px;
            background-color: #212529;
            position: relative;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .profileimage {
            width: 80px;
            height: 80px;
            background-color: #cccccc;
            border-radius: 50%;

            position: absolute;
            left: 0;
            right: 0;
            bottom: -40px;
            margin: auto;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            .input-file-editIcon {
                right: -8px;
                bottom: 0px;
                width: 25px;
                height: 25px;
                font-size: 12px;
            }
            
        }

        .input-file-upload {
            display: none;
        }

        .input-file-editIcon {
            position: absolute;
            right: 0;
            cursor: pointer;
            padding: 5px;
            width: 30px;
            height: 30px;
            font-size: 14px;
            margin: 10px 10px 0 0;
            background: #3861b3;
            color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .name {
            text-align: center;
            display: block;
            font-size: clamp(16px, 5vw, 22px);
        }
    }
}