.login {
    .card {
        input {
            text-transform: lowercase;
        }
    }
}

.login-signup-container{
    height: 100vh;
    background-color: #edf0f5;
    background-color: #212529;

    @media (max-width: 767px) {
        .btn-lg{
            font-size: 18px;
        }

        .form-control-lg{
            font-size: 16px;
        }
    }

    .forgot-password-link{
        text-decoration: none;
        margin-bottom: 20px;
        @media (max-width) {
            padding-bottom: 5px;
        } 

            &:hover {
                border-bottom: 1px solid;
                
            }
        }

    .login-logo{
        color: #ffffff;
        font-size: clamp(28px, 5vw, 40px);
    }

    .login-tagline{
        color: #ffffff;
        font-size: clamp(18px, 5vw, 24px);
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    .small-text{
        font-size: 12px;
    }

    .btn {
        &:disabled {
            cursor: no-drop;
        }
    }

    .popup-backdrop{
        position: fixed;
        background: rgba(255,255,255,0.5);
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
    }

    .popup-container{
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        padding: 1.5em  0;
        top: 0;
        bottom: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        animation: zoom-in 0.2s ease;
        overflow-y: auto;

        @media (max-width: 767px) {
            margin-top: 0;
            bottom: auto;
        }

        .card{
            max-width: 500px;
            margin: auto;
        }

        .close-icon{
            font-size: 1.8em;
            padding: 0;
            line-height: 1;
            position: absolute;
            right: 15px;
        }

        .loginBtn {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }

        .form-check-label {
            border-color: #ced4da;
            &-disabled {
                background: #eaecef;
                pointer-events: none;
            }
        }
    }
}

@keyframes zoom-in {
    0%{
        transform: scale(0.5);
    }
    100%{
        transform: scale(1);
    }
}