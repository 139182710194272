.connections-list-container{
    background: #fff;
    padding: 15px;
    box-shadow: 0px 4px 3px #ccc;
    border: 1px solid #ccc;

    &.grid-layout{
        padding: 0;
        border: 0;
        box-shadow: none;
        margin: 0 -5px;
    }

    h3{
        font-size: 18px;
        margin-bottom: 1rem;
    }

    .btn-viewall{
        font-size: 14px;
        font-weight: 600;
        color: #3861b3;
    }

    .list-group{
        &-item{
            display: flex;
            justify-content: space-between;

            .icon_box{
                text-align: right;
            }

            a{
                color: #212529;
                text-decoration: none;
                max-width: calc(100% - 70px);
            }
            .media{
                display: flex;
                align-items: center;
                .thumb{
                    width: 60px;
                    height: 60px;
                    object-fit: cover;
                }
            }
        }
    }
}