.verify-container {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #212529;

    .login-logo {
        color: #ffffff;
        font-size: clamp(28px, 5vw, 40px);
    }

    .title {
        color: #ffffff;
        font-size: clamp(28px, 5vw, 30px);
        margin-top: 2em;
        margin-bottom: 1em;
        font-weight: 300;
        letter-spacing: 1px;
    }
}