.connections{
    &-container{
        padding-left: 15px;
        padding-right: 15px;

        .title{
            font-size: 30px;
            font-weight: 400;
        }

        .credit-remaining-text{
            font-weight: 500;
            color: #3861b3;
        }

        .btn-getmorecredit{
            margin-left: 1em;
            border-width: 2px;
            color: #000000;
            font-weight: 600;

            &:hover{
                color: #ffffff;
            }
        }
    }
}

.popupConatiner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
}