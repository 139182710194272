@mixin activeLink {
    color: #ffffff;
    background-color: #3861b3;

    svg{
        color: #ffffff;
        transition: all 0.5s ease-in-out;
    }

    .icon{

        &-gift{
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
        }

        &-facebook{
            transform: scale(1.2);
            transition: all 0.5s ease-in-out;
        }

        &-instagram{
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
        }

        &-twitter{
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
        }
    }

    &::before{
        content: '';
        position: absolute;
        right: -5px;
        width: 50px;
        height: 2px;
        background-color: #ffffff;
    }

    &::after{
        content: '';
        background-color: #3861b3;
        position: absolute;
        right: -10px;
        width: 10px;
        height: 100%;
    }
}


.sidebar{
    &Container{
        max-width: 300px;
        z-index: 2;
        @media (max-width: 767px) {
            max-width: 250px;
            height: 100% !important;
            position: absolute;
            left: auto;
            right: 0;
            z-index: 999;
            transition: all 0.2s ease-in-out;
            margin-right: -300px;
            &.show{
                margin-right: 0;
                z-index: 999;
            }
        }
    }
    height: 100%;
    background-color: #212529;
    .sidebar-sticky{
        position: sticky;
        top: 0;
        height: calc(100vh - (45px + 34px));
        padding-top: .5rem;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #212529;
    }

    &-heading{
        color: #ffffff;
        font-size: .75rem;
        text-transform: uppercase;

        span, svg{
            color: #ffffff;
        }
    }

    .nav{
        &-link{
            font-size: 14px;
            display: flex;
            align-items: center;
            color: #ffffff;
            position: relative;
            &.active{
                @include activeLink;
            }

            svg{
                margin-right: 10px;
                color: #ffffff;
            }

            &:hover{
                @include activeLink;
            }

            .icon{

                &-gift{
                    fill: #fd1d1d;
                }
        
                &-facebook{
                    fill: #4267B2;
                }
        
                &-instagram{
                    fill: #C13584;
                }
        
                &-twitter{
                    fill: #1DA1F2;
                }
            }
        }

        &-item {
            position: relative;
            .cliamcredit {
                position: absolute;
                font-size: 10px;
                padding: 1px 5px;
                background: #3961b2;
                color: #ffffff;
                border-radius: 20px;
                top: 10px;
                right: 5px;
                font-weight: 600;
                cursor: pointer;
            }

            &:hover {
                .cliamcredit {
                    background-color: #ffffff;
                    color: #3961b2;
                }
            }
        }
    }
}

.buttonsGroup {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    flex-wrap: nowrap;

    @media (max-width: 575px) {
        flex-wrap: wrap;
    }

    a {
        width: 100%;
        padding: 8px 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;

        svg{
            margin-right: 5px;
        }
    }
}

.copyinputcontainer {
    input {
        height: auto;
        padding: 10px 8px;
    }
    .input-group-prepend{
        position: relative;
        .tooltip-text {
            font-size: 12px;
            position: absolute;
            top: -18px;
            margin: 0 auto;
            left: 0;
            right: 0;
            font-weight: 600;
            color: green;
        }

        .input-group-text{
            border: 0;
            border-radius: 0 0.25rem 0.25rem 0;
        }
    }
}
