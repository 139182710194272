.faq{
    &-questions-container{
        .accordion{
            .card{
                border: 0;
                border-radius: 0;
                margin-bottom: 1.2em;
                &-header{
                    padding: 0;

                    .btn{
                        text-decoration: none;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        color: #000000;
                        border-radius: 0;
                        font-weight: 500;
                        font-size: 14px;
                        letter-spacing: 1px;

                        display: flex;
                        align-items: center;

                        &::before, &::after{
                            content: '';
                            width: 12px;
                            height: 2px;
                            border-radius: 10px;
                            background-color: green;
                            position: absolute;
                            right: 15px;
                        }

                        &::after{
                            width: 2px;
                            height: 12px;
                            right: calc(15px + 0.35em);
                        }



                        &[aria-expanded="true"]{
                            color: #ffffff;
                            background-color: #3861b3;
                            
                            &::after{
                                display: none;
                            }

                            &::before{
                                background-color: #ffffff;
                            }

                        }
                    }
                }

                &-body{
                    padding: 10px;
                    padding-bottom: 0;
                }
            }
        }
    }
}