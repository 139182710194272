
@mixin reactBigCalendar() {
    .rbc-event {
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent !important;
        color: #000000 !important;

        &:focus {
            outline: none !important;
        }

        &-content {
            font-size: 11px;
        }

        &.rbc-selected {
            background-color: transparent !important;
            color: #265985 !important;
        }
    }

    .rbc-date-cell {

        .rbc-button-link {
            background: transparent;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 8px 5px 0 0;
            color: #000000;
        }

        &.rbc-current{
            .rbc-button-link {
                background: #3762b4;
                color: #ffffff;
            }
        }
    }

    @media (max-width: 590px) {
        .rbc-toolbar {
            gap: 10px;
            .rbc-toolbar-label {
                order: 2;
            }

            button {
                padding: 0.375rem 0.4rem;
                font-size: 14px;
            }
        }
    }
}

.schedule-calendar{
    &-container{
        padding-left: 15px; 
        padding-right: 15px;
        position: relative;
        z-index: 0;

        @media (max-width: 1096px) {
            padding: 0;
        }

        &.popup_open::before {
            content: "";
            background: rgba(0, 0, 0, 0.2);
            position: fixed;
            top: 0;
            width: calc(100% + 30px);
            height: calc(100vh + 45px);
            z-index: 1;
            left: -15px;
        }

        .title{
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 1em;
        }

        .Kalend__main * {
            font-family: 'system-ui';
        }

        .Kalend__Calendar__root {
            .Kalend__Calendar__table{
                margin-top: 5px;

                .Kalend__button {
                    div{
                        overflow: hidden;
                    }
                    .Kalend__text {
                        font-size: 11px !important;
                        margin: 0;
                        text-overflow: ellipsis;
                        width: 100%;
                        display: inline-block;
                    }
                }

                p.Kalend__text-dark {
                    margin: 0;
                }

                .Kalend__MonthWeekRow__container-events {
                    margin-top: 5px;
                }

                .Kalend__CalendarHeaderDates  {
                    &__circle-small {
                        height: 25px !important;
                        width: 25px !important;
                        border-radius: 15px;
                    }
                    &__primary {
                        background-color: #3861b3 !important;
                    }
                }
            }

            .Kalend__text {
                font-size: 16px !important;
                &.Kalend__Event__summary__type-month {
                    font-size: 11px !important;
                }
            }
            .Kalend__CalendarHeaderDates__circle-small-today, .Kalend__CalendarHeaderDates__circle-small-today-dark {
                background-color: #3961b2;
                width: 25px;
                height: 25px;
            }

            .Kalend__CalendarHeaderDates__circle-today, .Kalend__CalendarHeaderDates__circle-today-dark {
                background-color: #3961b2;
            }

            .Kalend__header_calendar_button-text-selected, .Kalend__header_calendar_button-text-selected-dark {
                color: #3961b2 !important;
            }
        }

        .Kalend__MonthView__container {
            position: relative;
        }

        .Kalend__Event-month {
            height: 14px !important;
            box-shadow: none !important;
            color: #000000 !important;
            font-weight: 600;
        }

        @include reactBigCalendar;
        
    }
}

.rbc-overlay {
    @include reactBigCalendar;
}