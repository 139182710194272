.change-password{
    &-container{
        padding-left: 15px;
        padding-right: 15px;
        .title{
            font-size: 30px;
            font-weight: 400;
        }
    }

    &-form{
        width: 400px;
        max-width: 100%;
    }
}