.faq{
    &-container{
        @media (min-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .title{
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 5px;
        }

        .subtitle{
            font-size: 15px;
            margin-bottom: 2em;
        }
    }
}