.feedback{
    &-container{

        .title, .subtitle{
            font-size: 30px;
            font-weight: 400;

            span{
                font-size: 25px;
                font-weight: 400;
                span{
                    color: #3861b3;
                }
            }
        }

        .subtitle {
            font-size: 24px;
            span {
                font-size: 22px;
                font-weight: 500;
            }
        }

        .instructions{
            font-size: 16px;
        }

        .social-channels{
            list-style: none;
            padding: 0;
            display: inline-flex;
            margin-bottom: 1em;

            li{

                &:not(:last-child){
                    margin-right: 10px;
                    @media (max-width: 767px) {
                        margin-right: 5px;
                    }
                }

                a{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 3px solid #dddddd;
                    transition: all 0.3s ease-in-out;

                    @media (max-width: 767px) {
                        width: 40px;
                        height: 40px;
                    }
    
                    &.facebook{
                        border-color: #4267B2;
                        svg{
                            fill: #4267B2;
                        }
                        &:hover {
                            background-color: #4267B2;
                            svg {
                                fill: #ffffff;
                            }
                        }
                    }
    
                    &.twitter{
                        border-color: #1DA1F2;
                        svg{
                            fill: #1DA1F2;
                        }
                        &:hover {
                            background-color: #1DA1F2;
                        
                            svg {
                                fill: #ffffff;
                            }
                        }
                    }
    
                    &.linkedin{
                        border-color: #2867B2;
                        svg{
                            fill: #2867B2;
                        }
                        &:hover {
                            background-color: #2867B2;
                        
                            svg {
                                fill: #ffffff;
                            }
                        }
                    }
    
                    &.gPlus{
                        border-color: #DB4437;
                        svg{
                            fill: #DB4437;
                        }
                        &:hover {
                            background-color: #DB4437;
                        
                            svg {
                                fill: #ffffff;
                            }
                        }
                    }
    
                    &.rss{
                        border-color: orange;
                        svg{
                            fill: orange;
                        }
                        &:hover {
                            background-color: orange;
                        
                            svg {
                                fill: #ffffff;
                            }
                        }
                    }
    
                    &.mail{
                        border-color: #545454;
                        svg{
                            fill: #545454;
                        }
                        &:hover {
                            background-color: #545454;
                        
                            svg {
                                fill: #ffffff;
                            }
                        }
                    }
                }
            }
        }

    }

    &-form {
        .btn-group {
            .btn {
                @media (max-width: 767px) {
                    padding: 5px 0;
                    font-size: 14px;
                }
            }
        }
    }
}