.btn-primary{
    background-color: #3861b3;
}

.custom-button{
    border: 0;
    outline: 0;
    font-size: 13px;
    letter-spacing: 1px;
    position: relative;

    &:focus{
        box-shadow: none;
        outline: none;
    }

    &:hover {
        &::before{
            content: "-1 Credit";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #3861b3;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}