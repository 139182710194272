.forgot-password-container{
    height: 100vh;
    background-color: #edf0f5;
    background-color: #212529;

    .btn-login {
        border-color: #3861b3;
        svg {
            path {
                stroke: #ffffff;
            }
        }
    }

    .card {
        background-color: transparent;
        border: 0;
        &-header {
            border: 0;
            background-color: transparent;
            h3{
                color: #ffffff;
                font-size: clamp(24px, 5vw, 28px);
            }
        }

        &-footer {
            border: 0;
        }

        &-body {
            p {
                color: #ffffff;
                font-size: 18px;
                text-align: center;
            }
        }

        .btn-fp {
            min-width: 100px;
            &:disabled {
                cursor: no-drop;
            }
        }

        input {
            font-size: 18px;
        }
    }

}