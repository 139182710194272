.navbar{
    background-color: #212529;
    &-brand{
        max-width: 300px;
        font-size: 18px;
        font-weight: 700;
        color: #ffffff;
        span{
            color: #3861b3;
        }

        @media (max-width: 560px) {
            font-size: 16px;
        }
    }

    .right-menu{
        max-width: 1450px;
        margin: 0 auto;
    }

    .search-icon {
        font-size: 20px;
    }

    .navbar-toggler {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
            @media (min-width: 768px) {
                display: none;
            }
            font-size: 11px;
            color: #ffffff;
        }
    }

    .btn-schedule{
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
            background-color: transparent;
            color: #000000;
            font-size: 20px;
            padding: 0;
        }
        svg{
            display: none;
        }
        @media (max-width: 767px) {
            flex-direction: column;
            svg{
                display: inline-block;
            }
            span{
                // display: none;
                font-size: 11px;
                color: #ffffff;
                letter-spacing: normal;
                font-weight: 400;
            }
            &::before {
                display: none !important;
            }
        }
    }

    .get_credit_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;

        a{
            text-decoration: none;
            color: #ffffff;
        }

        span{
            font-weight: 700;
            margin-left: 5px;
            font-size: 12px;
    
            @media (max-width: 767px) {
                display: none;
            }
        }
    
    }

    .search-input{
        font-size: 14px;
        line-height: 16px;
        height: 32px;
    }

}

.mobile-search-input-container{
    display: none;
    .visible{
        display: block;
    }
}

@media (min-width: 1920px) {
    .main-container{
        max-width: 1450px;
        margin: 0 auto;
    }
}

.Toastify__progress-bar {
    transform-origin: right !important;
}