@mixin sections-common-back($padding) {
    position: relative;
    padding: $padding;
    background: #f5f5f5;
    box-shadow: inset 0px 0px 5px 2px #ddd;
    border-radius: 3px;
}

.placeholder_text{
    color: #a7a7a7;
    font-size: 15px;
    line-height: 1.2;
}

@keyframes zoom-in {
    0%{
        transform: scale(0.5);
    }
    100%{
        transform: scale(1);
    }
}

@keyframes zoom-out {
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(0.5);
    }
}

.my-profile{

    &-container {
        .popup-backdrop {
            position: fixed;
            background: rgba(0, 0, 0, 0.6);
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 9999;
        }
        .popup-container{
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            padding: 0em 0;
            top: 0;
            bottom: 0;
            z-index: 9999;
            display: flex;
            align-items: center;
            overflow-y: auto;

            &.in{
                animation: zoom-in 0.2s ease;
            }

            .close-icon {
                font-size: 1.8em;
                padding: 0;
                line-height: 1;
                position: absolute;
                right: 15px;
            }

            .card{
                max-width: 800px;
                margin: auto;

                form {
                    display: flex;
                    flex-direction: column;
                    max-height: calc(100vh - 100px);

                    .card-body{
                        overflow: hidden;
                        overflow-y: auto;
                    }
                }
            }
        }
    }

    &-about-box{
        .h4{
            @media (max-width: 767px) {
                font-size: 20px;
            }
        }
    }

    &-box{
        @include sections-common-back(0 15px 20px);

        .input-file-upload{
            display: none;
        }

        .input-file-editIcon{
            position: absolute;
            right: 0;
            cursor: pointer;
            padding: 5px;
            width: 30px;
            height: 30px;
            font-size: 14px;
            margin: 10px 10px 0 0;
            background: #3861b3;
            color: #ffffff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .profile-banner {
			width: 100%;
			border: 0px solid #000;
			min-height: 120px;
			margin-bottom: 3em;
			// background: rgba(221,221,221,0.24);
            background-color: #212529;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @media (max-width: 767px) {
                min-height: 80px;
                margin-bottom: 3.5em;
            }

            .profile-image {
                width: 120px;
                height: 120px;
                margin-bottom: -2em;
                background: #ddd;
                border-radius: 50%;
                box-shadow: inset 0px 0px 3px 0px #000;
                position: absolute;
                left: 1.5em;
                top: 2em;
                background-size: cover;
                background-position: center center;

                @media (max-width: 767px) {
                    width: 90px;
                    height: 90px;
                    top: 2em;
                }

                input[type="file"] {
                    display: none;
                }

                label{
                    position: absolute;
                    right: 5px;
                    bottom: -5px;
                    width: 30px;
                    height: 30px;
                    margin: 0;
                    text-align: center;
                    border-radius: 50%;
                    cursor: pointer;
                    background: #3861b3;
                    color: #fff;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media (max-width: 767px) {
                        width: 25px;
                        height: 25px;
                        font-size: 12px;
                    }
                }
            }
		}

        .profile_field_icons{
            margin-right: 10px;
        }

        .btn-schedule-call {
            font-size: 14px;
        }

        .btn-editprofile {
            padding: 8px;
            width: 150px;
            box-shadow: 2px 3px 5px #ccc;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        
        .editPopup {
            margin-bottom: 0;
            font-size: 14px;
            padding: 0 15px;
            line-height: 25px;
            vertical-align: middle;
            color: #3861b3;
            font-weight: 600;
            display: inline-block;
            cursor: pointer;
            
            &:hover {
                color: #333333;
            }

            svg {
                font-size: 18px;
                margin-right: 0px;
            }
        }

        .name {
            color: #333;
            @media (max-width: 767px) {
                font-size: 20px;
            }
        }

        .designation {
            font-size: 15px;
        }

        .headline {
            @media (max-width: 767px) {
                font-size: 15px;
            }
        }

        .location {
            font-size: 15px;
            margin-top: 10px;
        }

        .workex_school {
            list-style: none;
            padding-left: 0;
            margin-top: 1em;
            margin-bottom: 0;

            li {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 8px;
                font-size: 15px;

                .thumb {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 5px;
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                    min-height: 30px;
                    border-radius: 2px;
                    text-align: center;
                    border: 0px solid #a7a7a7;
                    background-size: cover;
                    background-position: center;

                    @media (max-width: 767px) {
                        justify-content: flex-start;
                        margin-right: 12px;
                        width: 25px;
                        min-width: 25px;
                        height: 25px;
                        min-height: 25px;
                    }
                }
            }
        }
    }

    &-about-box{
        @include sections-common-back(15px);
        margin: 1em 0;

        .btn-editabout {
			position: absolute;
			right: 15px;
			font-size: 14px;
		}
    }
}